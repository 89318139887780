"use client";

import React, { PropsWithChildren } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { EnvVariables, QueryKeys } from "@/types";

const EnvVariablesProvider: React.FC<PropsWithChildren<{ envs: EnvVariables }>> = ({ children, envs }) => {
  useQuery({
    queryKey: [QueryKeys.CONFIG],
    queryFn: () => envs,
    initialData: envs,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  return children;
};

export const useEnvVariables = () => {
  const client = useQueryClient();
  return client.getQueryData<EnvVariables>([QueryKeys.CONFIG])!;
};

export default EnvVariablesProvider;
