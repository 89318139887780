import axios, { AxiosInstance } from "axios";

import { getSession, isAdminSession } from "@/helpers/sessionToken";
import {
  IAccount,
  IGetAccountsResponse,
  INameMatching,
  LevenshteinDistanceConfig,
  IAdminUserResponse,
  UPLOAD_FILE,
  CountryCode,
  ChartType,
  IChartData,
  IUserFilter,
  IAdminUserFormValues,
  ICloseRequestValues,
  ServiceTypes,
  ISlaReportResponse,
  ISoftwareStatement,
  IClientRegistrationValues,
  IDcrSettingsUpdateValues,
  IUpdateAuthServerValues,
  IGetAccountsUploadHistoryResponse,
  NotificationAlerts,
  AlertHistoryResponse,
} from "@/types";
import { ClientApp, AppProduct, User, TokenResponse } from "@/types/keycloak";
import { RegisterFormValues } from "@/components/publicPages/Register/registerSchema";
import { DcrRegisterFormValues } from "@/components/publicPages/Register/dcrRegisterSchema";
import { ContactUsFormValues } from "@/components/publicPages/Contact/contactUsSchema";
import { UpdatePasswordValues, UpdateUserValues } from "@/components/privatePages/Profile/schemas";
import { FormData } from "@/components/publicPages/NameMatchingGame/FirstScreen";

export const getRequestReport = (country: CountryCode, id: any, appApi: string, user: User) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return axios
    .get(`${appApi}/api/resources/request/${country.replace(" ", "-").toLowerCase()}/request-report/${id}`, {
      params: { userId: user.username || user.email, timeZone },
      headers: {
        authorization: `Bearer ${getSession()?.access_token}`,
      },
      responseType: "blob",
    })
    .then(res => {
      const url = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.download = `${id}_RequestReport.csv`;
      link.href = url;
      link.click();

      URL.revokeObjectURL(url);
    });
};

export const getBulkErrorReport = (
  country: CountryCode,
  id: string,
  type: UPLOAD_FILE,
  appApi: string,
  userId: string,
) => {
  return axios
    .get(
      `${appApi}/api/resources/${type === UPLOAD_FILE.BATCH_PAYEE ? `request` : "response"}/${country
        .replace(" ", "-")
        .toLowerCase()}/${
        type === UPLOAD_FILE.BATCH_PAYEE ? "batch" : type === UPLOAD_FILE.ACCOUNT_PERSONAL ? "personal" : "business"
      }/error-report/${id}`,
      {
        params: { userId },
        headers: {
          authorization: `Bearer ${getSession()?.access_token}`,
        },
        responseType: "blob",
      },
    )
    .then(res => {
      const url = URL.createObjectURL(res.data);
      const link = document.createElement("a");
      link.download = `Error Report.xls`;
      link.href = url;
      link.click();

      URL.revokeObjectURL(url);
    });
};

export const submitForm = async (values: FormData) => {
  const { data } = await axios.post<{ redirectUrl: string }>("/api/name-matching-game/submit", values);
  return data;
};

export const getSoftwareStatementInfoByOrgId = async (baseURI: string, orgId: string, query: Record<string, any>) => {
  const session = getSession();
  const { data } = await axios.get<{
    content: ISoftwareStatement[];
    page: number;
    size: number;
    totalElements: number;
    totalPages: number;
  }>(`${baseURI}/audits/software-statement-info/${orgId}`, {
    params: query,
    headers: { authorization: session?.access_token ? `Bearer ${session?.access_token}` : "" },
  });
  return data;
};

export const axiosRequests = (instance: AxiosInstance) => {
  return {
    getDocumentationPage: async (fileName: string, tenantName?: string) => {
      try {
        const { data } = await axios.get<string>(`/docs/text/${tenantName ? `${tenantName}/` : ""}${fileName}.md`);
        return data;
      } catch (e) {
        const { data } = await axios.get<string>(`/docs/text/bankc/${fileName}.md`);
        return data;
      }
    },
    postSession: async (body: { code: string; admin: boolean }) => {
      const { data } = await instance.post<TokenResponse>("/exchange", body);
      return data;
    },
    signUp: async ({ email, ...rest }: RegisterFormValues | (DcrRegisterFormValues & { dcrClient: boolean })) => {
      const { data } = await instance.post<{
        data: { error: boolean; error_description: string } | { client_id?: string };
      }>("/register", {
        ...rest,
        username: email,
      });
      return data.data;
    },
    logout: async () => {
      const { data } = await instance.post("/logout", {
        refresh_token: getSession()?.refresh_token,
        admin: isAdminSession(),
      });
      return data;
    },
    refreshToken: async (refresh_token: string) => {
      const { data } = await instance.post<TokenResponse>("/refresh", { refresh_token });
      return data;
    },
    contact: async (values: ContactUsFormValues) => {
      const { data } = await instance.post("/contact", values);
      return data;
    },
    getUser: async () => {
      const { data } = await instance.get<{ user: User }>("/getCurrentUser");
      return data.user;
    },

    // Developer User Applications, Products, Analytics & User updation
    getApps: async (page: number, limit: number, signal?: AbortSignal) => {
      const { data } = await instance.get<{ apps: ClientApp[]; totalApps: number }>(`/resources/apps`, {
        signal,
        params: { page, limit },
      });
      return data;
    },
    getApp: async (clientId: string, signal?: AbortSignal) => {
      const { data } = await instance.get<{ data: ClientApp }>("/resources/apps/" + clientId, { signal });
      return data.data;
    },
    putApp: async (body: ClientApp) => {
      const { data } = await instance.put("/resources/apps/" + body.clientId, body);
      return data;
    },
    postApp: async (body: ClientApp) => {
      const { data } = await instance.post("/resources/apps", body);
      return data;
    },
    deleteApp: async (appId: string) => {
      const { data } = await instance.delete(`/resources/apps/${appId}`);
      return data.data;
    },
    getProducts: async (devType: string, signal?: AbortSignal) => {
      const { data } = await instance.get<{ data: AppProduct[] }>(`/resources/products/${devType}`, {
        signal,
      });
      return data.data;
    },
    getAppsChartData: async (ids: string, type: ChartType, granularity: string, count: number) => {
      const { data } = await instance.get<{ data: IChartData[] }>(`/resources/analytics/${type}`, {
        params: { apps: ids, granularity, period: count },
      });
      return data.data;
    },
    updateUser: async (values: UpdateUserValues) => {
      const { data } = await instance.put("/session", values);
      return data;
    },
    updatePassword: async (values: UpdatePasswordValues) => {
      const { data } = await instance.put("/password", values);
      return data;
    },

    // Response Service
    getResponseAudits: async <T>(country: CountryCode, query: Record<string, any>) => {
      const { data } = await instance.get<T>(`/resources/response/${country}/transactions`, {
        params: query,
        responseType: query.format ? "blob" : "json",
      });
      return data;
    },
    getResponseAuditById: async <T>(country: CountryCode, id: string, userId: string) => {
      const { data } = await instance.get<{ result: T }>(`/resources/response/${country}/transactions/${id}`, {
        params: { userId },
      });
      return data.result;
    },
    getResponseParticipants: async <T>(country: CountryCode, query: Record<string, any>) => {
      const { data } = await instance.get<T>(`/resources/response/${country}/participants`, { params: query });
      return data;
    },
    getResponseParticipantsByOrgId: async <T>(country: CountryCode, orgId: string, query: Record<string, any>) => {
      const { data } = await instance.get<T>(`/resources/response/${country}/participants/${orgId}`, { params: query });
      return data;
    },
    getNameMatchingConfigs: async (country: CountryCode) => {
      const { data } = await instance.get<INameMatching>(`resources/response/${country}/config/name-matching`);
      return data;
    },
    updateNameMatchingConfig: async (country: CountryCode, body: INameMatching) => {
      const { data } = await instance.put<INameMatching>(`/resources/response/${country}/config/name-matching`, body);
      return data;
    },
    getLevenshteinDistanceConfigs: async (country: CountryCode) => {
      const { data } = await instance.get<LevenshteinDistanceConfig>(
        `resources/response/${country}/config/levenshtein-distance`,
      );
      return data;
    },
    updateLevenshteinDistanceConfigs: async (country: CountryCode, body: LevenshteinDistanceConfig) => {
      const { data } = await instance.put<LevenshteinDistanceConfig>(
        `/resources/response/${country}/config/levenshtein-distance`,
        body,
      );
      return data;
    },
    getRequestNameMatchingConfigs: async (country?: CountryCode) => {
      //const { data } = await instance.get<{ data: INameMatching }>("resources/request/settings");
      //return data.data;
      return { result: { descriptors: [] } } as any;
    },
    updateRequestNameMatchingConfig: async (country: CountryCode, body: INameMatching) => {
      //const { data } = await instance.put<INameMatching>("/resources/request/settings", body);
      //return data;
      return {};
    },

    // Responder Accounts (Admin user)
    getAccounts: async (country: CountryCode, query: Record<string, any>, signal?: AbortSignal) => {
      const { data } = await instance.get<IGetAccountsResponse>(`/resources/accounts/${country}`, {
        signal,
        params: query,
      });
      return data;
    },
    getAccount: async (country: CountryCode, query: Record<string, any>, signal?: AbortSignal) => {
      const { accountNumber, iban } = query;
      const { data } = await instance.get<IAccount>(
        `/resources/accounts/${country}/${accountNumber ? accountNumber : iban}`,
        {
          signal,
          params: query,
        },
      );
      return data;
    },
    postAccount: async (country: CountryCode, body: IAccount) => {
      const { data } = await instance.post(`/resources/accounts/${country}`, body);
      return data;
    },
    putAccount: async (country: CountryCode, body: IAccount) => {
      const { sortCode, accountRef, accountNumber, iban, bic } = body;
      const { data } = await instance.put(`/resources/accounts/${country}`, body, {
        params: { accountNumber, accountRef, sortCode, iban, bic },
      });
      return data;
    },
    deleteAccount: async (country: CountryCode, account: IAccount) => {
      const { accountType, sortCode, accountRef, accountNumber, iban, bic } = account;
      const { data } = await instance.delete(`/resources/accounts/${country}`, {
        params: { accountType, accountNumber, accountRef, sortCode, iban, bic },
      });
      return data;
    },
    getAccountsUploadHistory: async (country: CountryCode, query: Record<string, any>, signal?: AbortSignal) => {
      const { data } = await instance.get<IGetAccountsUploadHistoryResponse>(
        `/resources/accounts/${country}/upload-history`,
        {
          signal,
          params: query,
        },
      );
      return data;
    },
    getUploadHistoryReport: async (country: CountryCode, query: Record<string, any>) => {
      const { data } = await instance.get(`/resources/accounts/${country}/upload-history/error-report`, {
        params: query,
        responseType: "blob",
      });
      return data;
    },

    // Admin User
    getAdminUsers: async (filters: IUserFilter, page: number, limit: number, signal?: AbortSignal) => {
      const { data } = await instance.get<{ data: IAdminUserResponse }>(`/resources/users`, {
        signal,
        params: {
          page,
          limit,
          search: filters.searchValue,
          permission: filters.permission,
          active: filters.active,
          inActive: filters.inActive,
          userType: filters.userType,
          excludeExternal: filters.excludeExternal,
        },
      });
      return data.data;
    },
    createAdminUsers: async (values: IAdminUserFormValues) => {
      const { data } = await instance.post("/resources/users", values);
      return data.data;
    },
    updateAdminUsers: async (
      values: Partial<IAdminUserFormValues> & {
        id: User["id"];
        developerType: User["developerType"];
        apps: User["apps"];
        active?: boolean;
      },
    ) => {
      const { data } = await instance.put(`resources/users/${values.id}`, values);
      return data.data;
    },

    // Request Service
    getRequestAudits: async <T>(country: CountryCode, query: Record<string, any>, signal?: AbortSignal) => {
      const { data } = await instance.get<T>(`/resources/request/${country}/transactions`, {
        signal,
        params: query,
        responseType: query.format ? "blob" : "json",
      });
      return data;
    },
    getRequestAuditById: async <T>(country: CountryCode, id: string, userId: string, signal?: AbortSignal) => {
      const { data } = await instance.get<{ result: T }>(`/resources/request/${country}/transactions/${id}`, {
        signal,
        params: { userId },
      });
      return data.result;
    },
    getUniqueRequestBatches: async (country: CountryCode, query: Record<string, any>) => {
      const { data } = await instance.get(`/resources/request/${country}/unique-batches`, { params: query });
      return data;
    },
    addNotes: async (id: string, body: any, userId: string) => {
      const { country, ...note } = body;
      const code = country === "GB" ? "uk" : country.replace(" ", "-").toLowerCase();
      const { data } = await instance.put(`/resources/request/${code}/transactions/${id}/add-notes`, note, {
        params: { userId },
      });
      return data;
    },
    confirmPayee: async <T, U>(
      country: CountryCode,
      body: U & { userId: string; userType: string; clientId: string },
    ) => {
      const { userId, userType, clientId, ...rest } = body;
      const { data } = await instance.post<T>(`/resources/request/${country}/confirm-payee`, rest, {
        params: { userId, userType, clientId },
      });
      return data;
    },
    getMIReport: async (country: CountryCode, year: number) => {
      const { data } = await instance.get(`/resources/request/${country}/mi-report/${year}`);
      return data.data;
    },
    getMonthMIReport: async (country: CountryCode, year: number, month: number) => {
      const { data } = await instance.get(`/resources/request/${country}/mi-report/${year}/${month}`, {
        responseType: "blob",
      });
      return data;
    },
    getMIReportEmails: async (country: CountryCode) => {
      const { data } = await instance.get(`/resources/request/${country}/mi-report-emails/`);
      return data.data;
    },
    postEmailMiReport: async (country: CountryCode, body: { year: string; digitMonth: string }) => {
      const { data } = await instance.post(`/resources/request/${country}/mi-report`, body);
      return data;
    },
    putEmailsMiReport: async (country: CountryCode, body: { emails: string[]; body: string; subject: string }) => {
      const { data } = await instance.put(`/resources/request/${country}/mi-report-emails`, body);
      return data;
    },
    postVocalink: async (
      country: CountryCode,
      body: { api: string; token: string; clientId: string; secret: string },
    ) => {
      const { data } = await instance.post(`/resources/request/${country}/vocalink`, body);
      return data;
    },
    postVocalinkShare: async (
      country: CountryCode,
      body: { endpoint: string; username: string; password: string; path: string },
    ) => {
      const { data } = await instance.post(`/resources/request/${country}/vocalink-share`, body);
      return data;
    },

    getMIReportSchedulerStatus: async (country: CountryCode) => {
      const { data } = await instance.get(`/resources/request/${country}/mi-report-scheduler`);
      return data;
    },
    postMIReportSchedulerStatus: async (country: CountryCode, status: any) => {
      const { data } = await instance.post(`/resources/request/${country}/mi-report-scheduler`, status);
      return data;
    },
    closeRequest: async ({ country, id, userId }: ICloseRequestValues) => {
      const { data } = await instance.post(`/resources/request/${country}/close-request`, {
        id,
        userId,
      });
      return data;
    },

    getSlaReport: async (
      country: CountryCode,
      serviceName: ServiceTypes,
      since: string,
      until: string,
      granularity: string,
      page: number,
      size: number,
      sort: string,
      format?: string,
      hideRequest?: boolean,
      hideResponse?: boolean,
    ) => {
      const { data } = await instance.get<ISlaReportResponse>(
        `/resources/analytics/performance/${country}/${serviceName}`,
        {
          params: { since, until, granularity, page, size, sort, format, hideRequest, hideResponse },
          responseType: format ? "blob" : "json",
        },
      );
      return data;
    },
    getRequestParticipants: async <T>(country: CountryCode, query: Record<string, any>, signal?: AbortSignal) => {
      const { data } = await instance.get<T>(`/resources/request/${country}/participants`, { signal, params: query });
      return data;
    },
    getRegistrationAudits: async <T>(country: CountryCode, id: string, signal?: AbortSignal) => {
      const { data } = await instance.get<T>(`/resources/request/${country}/manual-dcr/${id}`, { signal });
      return data;
    },
    getSoftwareStatements: async (country: CountryCode, signal?: AbortSignal) => {
      const { data } = await instance.get<ISoftwareStatement[]>(`/resources/request/${country}/software-statements`, {
        signal,
      });
      return data;
    },
    generateSSA: async (country: CountryCode, body: { softwareStatementId: string }) => {
      const { data } = await instance.post<string>(`/resources/request/${country}/software-statements/ssa`, body);
      return data;
    },
    performClientRegistration: async (country: CountryCode, body: IClientRegistrationValues) => {
      const { data } = await instance.post(`/resources/request/${country}/manual-dcr`, body);
      return data;
    },
    modifyClientRegistration: async (country: CountryCode, body: IClientRegistrationValues) => {
      const { data } = await instance.put(`/resources/request/${country}/manual-dcr`, body);
      return data;
    },
    deleteClientRegistration: async (country: CountryCode, body: IClientRegistrationValues) => {
      const { data } = await instance.delete(`/resources/request/${country}/manual-dcr`, { data: body });
      return data;
    },
    getClientRegistrationBulkAudits: async (country: CountryCode, query: Record<string, any>, signal?: AbortSignal) => {
      const { data } = await instance.get(`/resources/request/${country}/manual-dcr/bulk-audits`, {
        signal,
        params: query,
      });
      return data;
    },
    getDcrBulkAudits: async (country: CountryCode, query: Record<string, any>, signal?: AbortSignal) => {
      const { data } = await instance.get(`/resources/request/${country}/dcr-audits`, { signal, params: query });
      return data;
    },
    getDcrSettings: async (country: CountryCode) => {
      const { data } = await instance.get<IDcrSettingsUpdateValues>(`/resources/request/${country}/dcr-settings`);
      return data;
    },
    postDcrSettings: async (country: CountryCode, body: IDcrSettingsUpdateValues) => {
      const { data } = await instance.post<string>(`/resources/request/${country}/dcr-settings`, body);
      return data;
    },
    updateAuthServer: async (country: CountryCode, body: IUpdateAuthServerValues) => {
      const { data } = await instance.post<string>(
        `/resources/request/${country}/update-auth-server/${body.authServerId}`,
        body,
      );
      return data;
    },
    refreshCacheSettings: async (country: CountryCode) => {
      const { data } = await instance.post<string>(`/resources/request/${country}/refresh-list`);
      return data;
    },

    // Dashboard Analytics
    getServiceAnalytics: async <T>(
      service: ServiceTypes,
      country: CountryCode,
      period: { startTime: string; endTime: string },
      signal?: AbortSignal,
    ) => {
      const { data } = await instance.get<T>(`/resources/analytics/${country}/${service}`, {
        signal,
        params: period,
      });
      return data;
    },

    // Notification Settings
    getNotificationAlerts: async () => {
      const { data } = await instance.get<NotificationAlerts>(`/resources/notifications`);
      return data;
    },
    postNotificationAlerts: async (body: Partial<Omit<NotificationAlerts, "id">>) => {
      const { data } = await instance.post(`/resources/notifications`, body);
      return data;
    },
    updateNotificationAlerts: async (body: Partial<NotificationAlerts>) => {
      const { data } = await instance.put(`/resources/notifications/${body.id}`, body);
      return data;
    },
    getAlertHistory: async (query: Record<string, string | number>) => {
      const { data } = await instance.get<AlertHistoryResponse>(`/resources/notifications/history`, {
        params: query,
      });
      return data;
    },

    // Bulk Uploads
    uploadFile: async (
      country: CountryCode,
      input: { data: any; type: string; userInfo: User; payeeAuthClientId?: string },
    ) => {
      const { userInfo, payeeAuthClientId } = input;

      const accountType = input.type === UPLOAD_FILE.ACCOUNT_PERSONAL ? "personal" : "business";
      const params =
        input.type === UPLOAD_FILE.BATCH_PAYEE
          ? {
              userId: userInfo.email || userInfo.username,
              userType: userInfo.developerType,
              clientId: payeeAuthClientId ?? "",
            }
          : {};

      return await instance.post(
        input.type === UPLOAD_FILE.BATCH_PAYEE
          ? `/resources/request/${country}/batch-payee/upload`
          : `/resources/response/${country}/${accountType}/upload`,
        input.data,
        { params },
      );
    },
  } as const;
};
