"use client";

import React, { createContext, PropsWithChildren, useContext } from "react";
import { useRouter } from "next/navigation";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";

import { useEnvVariables } from "@/config/EnvVariablesProvider";
import { getSession, removeSession, setSession } from "@/helpers/sessionToken";
import { axiosRequests } from "@/requests";
import { QueryKeys, Routes } from "@/types";

const AxiosBaseContext = createContext<ReturnType<typeof axiosRequests> | undefined>(undefined);

export const AxiosInstanceProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const client = useQueryClient();
  const envs = useEnvVariables();

  const router = useRouter();

  const axiosInstance = axios.create({ baseURL: envs.apiBase });
  const requests = axiosRequests(axiosInstance);

  axiosInstance.interceptors.request.use(
    config => {
      const session = getSession();
      if (session?.access_token) {
        config.headers.setAuthorization(`Bearer ${session.access_token}`);
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    response => {
      return response;
    },
    async err => {
      const originalConfig = err.config;
      const session = getSession();

      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry && session?.refresh_token) {
        originalConfig._retry = true;
        try {
          const data = await requests.refreshToken(session?.refresh_token);
          setSession({ ...data, timestamp: new Date().getTime() });

          axiosInstance.defaults.headers.common.authorization = `Bearer ${data.access_token}`;

          return axiosInstance(originalConfig);
        } catch (_error) {
          removeSession();
          // client.clear();
          client.setQueryData([QueryKeys.USER], () => null);
          router.replace(Routes.HOME);
          return Promise.reject(_error);
        }
      }

      return Promise.reject(err);
    },
  );

  return <AxiosBaseContext.Provider value={requests}>{children}</AxiosBaseContext.Provider>;
};

export const useGetAxiosRequests = () => {
  const context = useContext(AxiosBaseContext);
  if (!context) {
    throw new Error("useGetAxiosRequests is called outside of the Provider");
  }
  return context;
};
