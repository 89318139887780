import { QueryCache, QueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast/headless";

import logger from "@/config/logger";

const makeQueryClient = () =>
  new QueryClient({
    queryCache: new QueryCache({
      onError: (error, query) => {
        // TODO: Global error toast for all useQuery hooks
        logger.info({ queryKey: query.queryKey[0] });
        // toast.error(error?.response?.data.message);
      },
    }),
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        gcTime: 1000,
      },
      mutations: {
        // mutation options
      },
    },
  });

export default makeQueryClient;
