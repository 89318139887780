"use client";

import { Alert, alertClasses } from "@mui/material";
import { styled, lighten } from "@mui/material/styles";

const StyledAlert = styled(Alert)(({ theme }) => ({
  padding: "4px 8px",
  fontWeight: "bold",
  fontSize: "15px",
  alignItems: "center",

  [`&.${alertClasses.outlined}`]: {
    [`&.${alertClasses.colorSuccess}`]: {
      backgroundColor: lighten(theme.palette.success.main, 0.9),
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.success.main,
      },
    },
    [`&.${alertClasses.colorInfo}`]: {
      backgroundColor: lighten(theme.palette.info.main, 0.9),
      borderColor: theme.palette.info.main,
      color: theme.palette.info.main,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.info.main,
      },
    },
    [`&.${alertClasses.colorError}`]: {
      backgroundColor: lighten(theme.palette.error.main, 0.9),
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.error.main,
      },
    },
    [`&.${alertClasses.colorWarning}`]: {
      backgroundColor: lighten(theme.palette.warning.main, 0.9),
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.warning.main,
      },
    },
  },
  [`&.${alertClasses.standard}`]: {
    [`&.${alertClasses.colorSuccess}`]: {
      color: theme.palette.success.main,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.success.main,
      },
    },
    [`&.${alertClasses.colorInfo}`]: {
      color: theme.palette.info.main,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.info.main,
      },
    },
    [`&.${alertClasses.colorError}`]: {
      color: theme.palette.error.main,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.error.main,
      },
    },
    [`&.${alertClasses.colorWarning}`]: {
      color: theme.palette.warning.main,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.warning.main,
      },
    },
  },

  [`& .${alertClasses.message}`]: {
    padding: 0,
  },
  [`& .${alertClasses.icon}`]: {
    padding: 0,
  },
  [`& .${alertClasses.action}`]: {
    paddingTop: 0,
    paddingRight: 4,
  },
}));

export default StyledAlert;
