import { SessionData } from "@/types/keycloak";

export const setSession = (data: SessionData) => {
  if (typeof window !== "undefined") {
    return sessionStorage.setItem("session", JSON.stringify(data));
  }
  return null;
};

export const isAdminSession = () => {
  return !!getSession()?.adminSession;
};

export const getSession = (): SessionData | null => {
  if (typeof window !== "undefined") {
    const session = sessionStorage.getItem("session");
    if (session) {
      return JSON.parse(session) as SessionData;
    }
  }
  return null;
};

export const removeSession = () => {
  if (typeof window !== "undefined") {
    sessionStorage.removeItem("session");
  }
};
