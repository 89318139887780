import { Inter, IBM_Plex_Mono, Archivo, Roboto } from "next/font/google";

import { darkScrollbar } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

import { EnvVariables } from "@/types";

declare module "@mui/material/styles" {
  interface Palette {
    green?: Palette["primary"];
  }
  interface PaletteOptions {
    green?: PaletteOptions["primary"];
  }
  interface TypeBackground {
    secondary?: TypeBackground["default"];
    bgGrey?: TypeBackground["default"];
  }
}

export const inter = Inter({ subsets: ["latin"] });
export const IbmPlexMono = IBM_Plex_Mono({ weight: ["400", "500", "600", "700"], subsets: ["latin"] });
export const archivo = Archivo({ subsets: ["latin"] });
export const roboto = Roboto({ weight: ["400", "500", "700"], subsets: ["latin"] });

// Create a theme instance.
const getTheme = (config?: EnvVariables) =>
  responsiveFontSizes(
    createTheme({
      palette: {
        primary: {
          main: config?.primaryColor || "#044D72",
        },
        secondary: {
          main: config?.secondaryColor || "#E78D69",
        },
        error: {
          main: "#E04B51",
        },
        success: {
          main: "#079453",
        },
        warning: {
          main: "#F09A1A",
        },
        info: {
          main: "#009DE9",
        },
        background: {
          default: "#F8F8F8",
          secondary: "#F3F3F3",
          bgGrey: "#EEF0EF",
        },
        text: {
          primary: "#0E1210",
          secondary: "#6A6A6A",
          disabled: "#B1B4BA",
        },
      },
      typography: {
        fontFamily: `${inter.style.fontFamily}, ${IbmPlexMono.style.fontFamily}, ${archivo.style.fontFamily}, ${roboto.style.fontFamily}, 'Arial', sans-serif`,
        h1: { fontWeight: "bold", fontSize: 48, textAlign: "center" },
        h2: { fontWeight: "bold", fontSize: 31, textAlign: "center" },
        h3: { fontWeight: "bold", fontSize: 24, textAlign: "center" },
        h4: { fontWeight: "bold", fontSize: 20, textAlign: "center" },
        h5: { fontWeight: "600", fontSize: 16 },
        h6: { fontWeight: "600", fontSize: 14 },
        subtitle1: {},
        subtitle2: {},
        body1: {},
        body2: { fontSize: 14 },
        button: {
          textTransform: "none",
        },
        caption: {},
        overline: {},
      },

      components: {
        MuiCssBaseline: {
          styleOverrides: {
            html: {
              ...darkScrollbar({ track: grey[200], thumb: grey[400], active: grey[400] }),
              //scrollbarWidth for Firefox
              scrollbarWidth: "thin",
            },
          },
        },

        MuiTextField: {
          defaultProps: {
            size: "small",
            fullWidth: true,
          },
        },
        MuiTooltip: {
          defaultProps: {
            arrow: true,
            placement: "top",
          },
          styleOverrides: {
            tooltip: ({ ownerState, theme }) => ({
              backgroundColor: theme.palette.common.white,
              boxShadow: "0px 16px 24px rgba(10, 31, 68, 0.16)",
              color: theme.palette.text.secondary,
              padding: "8px 12px",
              maxWidth: "316px",
            }),
            arrow: ({ ownerState, theme }) => ({
              "&:before": {
                backgroundColor: theme.palette.common.white,
              },
            }),
          },
        },
        MuiPaper: {
          styleOverrides: {
            elevation1: {
              boxShadow: "0px 16px 24px rgba(10, 31, 68, 0.16)",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: ({ ownerState, theme }) => ({
              borderRadius: "4px",
              boxShadow: "none",
              fontSize: "14px",
              fontWeight: 600,
              "&:hover": { boxShadow: "none" },
              ...(ownerState.variant === "contained" && { color: theme.palette.common.white }),
              ...(ownerState.variant === "outlined" && {
                border: "1px solid #DBDBDB",
                color: theme.palette.common.black,
              }),
            }),
          },
        },
        MuiTableHead: {
          styleOverrides: {
            root: ({ ownerState, theme }) => ({
              th: {
                fontWeight: "600",
              },
            }),
          },
        },
      },
    }),
  );

export default getTheme;
