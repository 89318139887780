import pino from "pino";

const logger = pino({
  level: process.env.NODE_ENV === "production" ? "info" : "debug",
  timestamp: pino.stdTimeFunctions.isoTime,
  // messageKey: "message",
  // errorKey: "error",
  formatters: {
    level: label => {
      return { level: label.toUpperCase() };
    },
    bindings: bindings => {
      return {};
    },
  },
  redact: {
    paths: ["*.password"],
    censor: "***********",
  },

  browser: {
    asObject: true,
    formatters: {
      level: label => {
        return { level: label.toUpperCase() };
      },
    },
  },
});

export default logger;
