"use client";

import React, { createContext, PropsWithChildren, useState, useMemo, useContext, useCallback } from "react";

import { useEnvVariables } from "@/config/EnvVariablesProvider";
import { CountryCode } from "@/types";

export const getCountrySession = () => {
  if (typeof window !== "undefined") {
    return sessionStorage.getItem("country") as CountryCode;
  }
  return null;
};

export const setCountrySession = (country: CountryCode) => {
  if (typeof window !== "undefined") {
    sessionStorage.setItem("country", country);
  }
};

const CountryContext = createContext({
  country: CountryCode.UK,
  setCountry: (() => {}) as (country: CountryCode) => void,
});

const CountryProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { defaultCountry } = useEnvVariables();

  const [country, setCountry] = useState<CountryCode>(() => {
    return getCountrySession() ?? (defaultCountry as CountryCode);
  });

  const handleSetCountry = useCallback((country: CountryCode) => {
    setCountry(country);
    setCountrySession(country);
  }, []);

  const countryValue = useMemo(
    () => ({
      country,
      setCountry: handleSetCountry,
    }),
    [country, handleSetCountry],
  );

  return <CountryContext.Provider value={countryValue}>{children}</CountryContext.Provider>;
};

export const useCountry = () => {
  const context = useContext(CountryContext);
  if (!context) {
    throw new Error("useCountry is called outside of the Provider");
  }
  return context;
};

export default CountryProvider;
