"use client";

import React, { FC, PropsWithChildren } from "react";
import moment from "moment";

import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import getTheme from "@/config/theme";
import { useEnvVariables } from "@/config/EnvVariablesProvider";

const locales = { "en-us": "en-us" };

const MuiProvider: FC<PropsWithChildren> = ({ children }) => {
  const envs = useEnvVariables();

  // Some of the moment methods do not support scoped locales. For accurate localization, you will have to manually update the global locale before updating it in LocalizationProvider.
  if (moment.locale() !== locales["en-us"]) {
    moment.locale(locales["en-us"]);
  }

  return (
    <ThemeProvider theme={getTheme(envs)}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locales["en-us"]}>
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default MuiProvider;
