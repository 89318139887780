"use client";

import React from "react";

import {
  useToaster,
  resolveValue,
  ToasterProps,
  ToastPosition,
  Toast,
  Renderable,
  toast,
} from "react-hot-toast/headless";

import { AlertColor, AlertTitle, Slide } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import StyledAlert from "@/components/common/StyledAlert";

const ToastWrapper = ({ id, className, style, onHeightUpdate, children }: any) => {
  const ref = React.useCallback(
    (el: HTMLElement | null) => {
      if (el) {
        const updateHeight = () => {
          const height = el.getBoundingClientRect().height;
          onHeightUpdate(id, height);
        };
        updateHeight();
        new MutationObserver(updateHeight).observe(el, {
          subtree: true,
          childList: true,
          characterData: true,
        });
      }
    },
    [id, onHeightUpdate],
  );

  return (
    <div ref={ref} className={className} style={style}>
      {children}
    </div>
  );
};

const getPositionStyle = (position: ToastPosition, offset: number): React.CSSProperties => {
  const top = position.includes("top");
  const verticalStyle: React.CSSProperties = top ? { top: 0 } : { bottom: 0 };
  const horizontalStyle: React.CSSProperties = position.includes("center")
    ? {
        justifyContent: "center",
      }
    : position.includes("right")
      ? {
          justifyContent: "flex-end",
        }
      : {};
  return {
    left: 0,
    right: 0,
    display: "flex",
    position: "absolute",
    transition: `all 230ms cubic-bezier(.21,1.02,.73,1)`,
    transform: `translateY(${offset * (top ? 1 : -1)}px)`,
    ...verticalStyle,
    ...horizontalStyle,
  };
};

const DEFAULT_OFFSET = 16;

const Notification: React.FC<ToasterProps> = ({
  reverseOrder,
  position = "top-center",
  toastOptions,
  gutter,
  children,
  containerStyle,
  containerClassName,
}) => {
  const { toasts, handlers } = useToaster(toastOptions);

  return (
    <div
      style={{
        position: "fixed",
        zIndex: 9999,
        top: DEFAULT_OFFSET,
        left: DEFAULT_OFFSET,
        right: DEFAULT_OFFSET,
        bottom: DEFAULT_OFFSET,
        pointerEvents: "none",
        ...containerStyle,
      }}
      className={containerClassName}
      onMouseEnter={handlers.startPause}
      onMouseLeave={handlers.endPause}
    >
      {toasts.map(t => {
        const toastPosition = t.position || position;
        const offset = handlers.calculateOffset(t, {
          reverseOrder,
          gutter,
          defaultPosition: position,
        });
        const positionStyle = getPositionStyle(toastPosition, offset);

        return (
          <ToastWrapper
            id={t.id}
            key={t.id}
            onHeightUpdate={handlers.updateHeight}
            className={t.visible ? "activeClass" : ""}
            style={positionStyle}
          >
            {t.type === "custom" ? (
              resolveValue(t.message, t)
            ) : children ? (
              children(t)
            ) : (
              <ToastBar toast={t} position={toastPosition} />
            )}
          </ToastWrapper>
        );
      })}
    </div>
  );
};

interface ToastBarProps {
  toast: Toast;
  position?: ToastPosition;
  style?: React.CSSProperties;
  children?: (components: { icon: Renderable; message: Renderable }) => Renderable;
}

const getSlideDirection = (position: ToastPosition) => {
  const pos = {
    "top-left": "right",
    "top-center": "down",
    "top-right": "left",
    "bottom-left": "right",
    "bottom-center": "up",
    "bottom-right": "left",
  } as const;
  return pos[position];
};

// eslint-disable-next-line react/display-name
export const ToastBar: React.FC<ToastBarProps> = React.memo(({ toast: toastProps, position, style, children }) => {
  const theme = useTheme();
  const defaultTransitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const direction = getSlideDirection(toastProps.position || position || "top-center");

  const icon = toastProps.icon || null;
  const message = resolveValue(toastProps.message, toastProps);

  let type = toastProps.type as AlertColor;
  if (toastProps.type === "loading") type = "info";
  if (toastProps.type === "custom") type = "info";
  if (toastProps.type === "blank") type = "warning";

  return (
    <Slide in={toastProps.visible} direction={direction} timeout={defaultTransitionDuration} mountOnEnter unmountOnExit>
      <StyledAlert
        severity={type}
        variant="outlined"
        sx={[{ minWidth: "320px" }, toastProps.sx ?? {}]}
        icon={icon}
        style={{
          pointerEvents: "visible",
          ...style,
          ...toastProps.style,
        }}
        className={toastProps.className}
        onClose={() => toast.dismiss(toastProps.id)}
        action={toastProps.action}
      >
        {toastProps.title && <AlertTitle>{toastProps.title}</AlertTitle>}
        {typeof children === "function"
          ? children({
              icon,
              message,
            })
          : message}
      </StyledAlert>
    </Slide>
  );
});

export default Notification;
